/* Style pour la barre de navigation */
.page-container {
  background-color: #F7F9F9;
  padding: 20px;
}

/* Style pour le breadcrumb */
.page-container h6 {
  margin-top: 10px;
  font-size: 16px;
  color: #183152;
}

.page-container h6 a {
  color: #183152;
  text-decoration: none;
}

.page-container h6 span {
  cursor: pointer;
  color: #183152;
}

/* Style pour le tableau */
.table-responsive {
  padding: 10px;
  border-radius: 5px;
  background-color: #FFFFFF;
  border: 1px solid #DADADA;
}

table.table-striped {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

table.table-striped colgroup col {
  width: auto;
}

table.table-striped th {
  background-color: #183152;
  color: #FFFFFF;
  text-align: center;
  padding: 10px;
  vertical-align: middle;
  font-size: 14px;
}

table.table-striped td {
  text-align: center;
  padding: 8px;
  vertical-align: middle;
  font-size: 14px;
}

table.table-striped thead tr {
  height: 50px;
}

table.table-striped tbody tr {
  background-color: #FFFFFF;
}

table.table-striped tbody tr:nth-child(even) {
  background-color: #F7F9F9;
}

/* Style pour les entrées de filtre */
.filter-input {
  width: 100%;
  padding: 5px;
  border: 1px solid #CED4DA;
  border-radius: 3px;
  font-size: 14px;
}

.filter-input::placeholder {
  font-size: 12px;
}

.filter-select {
  width: 100%;
  padding: 5px;
  border: 1px solid #CED4DA;
  border-radius: 3px;
  font-size: 14px;
  text-align: center;
}

/* Style pour les boutons d'action */
.btn {
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 3px;
}

.btn-primary {
  background-color: #007BFF;
  border-color: #007BFF;
  color: #FFFFFF;
}

.btn-secondary {
  background-color: #6C757D;
  border-color: #6C757D;
  color: #FFFFFF;
}

/* Style pour les liens */
a {
  color: #007BFF;
  text-decoration: underline;
}

a:hover {
  color: #0056b3;
}

/* Style pour les checkbox */
input[type='checkbox'] {
  transform: scale(1.2);
  margin: 0 10px;
}

/* Style pour les colonnes spécifiques */
.table-striped th[style*='width: 200px'] {
  width: 200px !important;
}

.table-striped th[style*='width: 280px'] {
  width: 280px !important;
}

.table-striped th[style*='width: 100px'] {
  width: 100px !important;
}

.table-striped th[style*='width: 80px'] {
  width: 80px !important;
}

.table-striped th[style*='width: 150px'] {
  width: 150px !important;
}

.table-striped th[style*='width: 90px'] {
  width: 90px !important;
}

/* Style pour les cases de filtre */
.table-striped tbody tr td input[type="text"],
.table-striped tbody tr td input[type="date"],
.table-striped tbody tr td select {
  width: 90%;
  height: 25px;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #DADADA;
  border-radius: 4px;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.table-striped tbody tr td select {
  padding-left: 8px;
}

