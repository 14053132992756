.arrowStep{
    margin-top: auto;
    margin-bottom: auto;
}

.FormSelect {
    width: 300px;
    margin: 0 auto 30px auto;
    border-color: #3c6382;
    color: #3c6382
}

.jsonEditor{
    width: 400px;
    margin: 0 auto;
}

@media (max-width: 600px){

    .arrowStep{
        margin: auto;
    }

    .FormSelect {
        width: 276px;
        margin: 0 auto 30px auto;
        border-color: #3c6382;
        color: #3c6382
    }

    .jsonEditor{
        width: 276px;
        margin: 0 auto;
    }

}