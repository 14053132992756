.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px; /* Ajout de padding pour que les éléments ne soient pas collés au bord */
  background-color: transparent; /* Enlève le bandeau bleu */
}

.sidebar {
  position: fixed;
  left: -250px;
  top: 0;
  width: 250px;
  height: 100%;
  transition: left 0.3s;
  z-index: 10;
}

.sidebar.open {
  left: 0;
}

.custom-navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: transparent; /* Enlève le fond bleu */
}

.logo {
  margin-right: 10px;
}

.navbar-toggler {
  border: none;
  /*background: #235073;  Assure que le burger reste bleu */
  z-index: 11;
  padding: 5px; /* Ajout de padding pour une meilleure visibilité */
}

.navbar-toggler:focus {
  outline: none;
}

.user-info {
  display: flex;
  align-items: center;
}

.navbar-collapse {
  display: none !important;
}

.nav_item_position{
  width: 90%; 
  text-align:left;
  margin-left:10%; 
}

.nav_item_position[data-type="premier"]{
  margin-top:25%;
}

@media (max-width: 600px){
  .sidebar{
    width: 125px;
  }

  .nav_item_position{
    width: 80%; 
    text-align:left;
    margin-left:20%; 
  }

  .nav_item_position[data-type="premier"]{
    margin-top:50%;
  }
}