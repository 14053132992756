
@media (min-width: 601px) {
  
  .stats-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Ajustez selon le besoin pour l'alignement vertical */
  }
  
  .table-container {
    flex: 1; /* Ajustez selon le besoin pour contrôler la largeur */
    /* Optionnel : ajoutez une marge ou un padding si nécessaire */
  }
  
    .chart-container {
      width: 100%;
      height: 300px;
      flex: 1;
    } 

    .position_stats_graph{
      width: 50vw;
      display: flex;
    }
    
    .div_stats{
      width: 100vw;
    }
    
    .div_stats_menu{
      display: flex;
    }
    
    .div_stats_menu_fleche{
      margin-top:10px; 
      margin-right:10px; 
      margin-left:auto;
    }
    
    .cadres_stats{
      display: flex;
      justify-content: flex-end; 
      margin-bottom:10px;
    }
    
    .cadres_stats_doughnut{
      margin-right: 10px;
    }
    
    .size-stats{
      margin-right: auto;
      margin-left: auto;
      width: 600px;
    }
  }
  
  @media (max-width: 600px) {
    
    .div_stats_menu_fleche{
      margin-left: 10px;
    }
    
    .cadres_stats{
      display: flex;
      flex-direction: column;
      margin-bottom:10px;
      align-items: center;
    }

    .cadres_stats_doughnut{
      margin-bottom: 10px;
    }

    .size-stats{
      width: 100%;
      margin-bottom: 10px;
    }
    
    .position_stats{
      justify-content: center;
    }

    .chart-container{
      height: auto;
      width: 100%;
    }

    .position_stats_graph{
      width: 60vw;
      margin: auto;
    }
 
  }
 
  